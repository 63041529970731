<template>
	<div id="Smart0">
		<Header2022 :navbg='true'></Header2022>
		<div class="spot-wrap">
			<img class="iframe-image" src="../../../assets/images/index2022/smart0.png"  v-if="browser=='Edge'">
			<iframe ref="iframe" src="https://app.modaiyun.com/damf/rendering-viewer/pano/LxXcz7uWsx?isRoaming=true" frameborder="0"
				style="width: 100%;height: 100%;" v-else></iframe>
			<div class="btn-wrap">
				<el-button @click="followTap">关注订制</el-button>
			</div>
		</div>
	<!-- 	<div class="main-wrap">
			<div class="main-title">
				<img src="../../../assets/images/smart0/smart0-logo.png" alt="">
				零碳智慧住宅
			</div>
			<div class="main-subtitle">
				集自主绿能、科技与美感为一体的未来城市住宅
			</div>
			<div class="lightspot">
				<i><a href="#section0" rel="external nofollow" @click="scrollToAnchor">自主绿能</a></i>
				<el-divider direction="vertical"></el-divider>
				<i><a href="#section1" rel="external nofollow" @click="scrollToAnchor">智慧恒温</a></i>
				<el-divider direction="vertical"></el-divider>
				<i><a href="#section2" rel="external nofollow" @click="scrollToAnchor">生态庭院</a></i>
				<el-divider direction="vertical"></el-divider>
				<i><a href="#section3" rel="external nofollow" @click="scrollToAnchor">绿色出行</a></i>
			</div>
			<div class="btn-wrap">
				<el-button @click="followTap">关注订制</el-button>
			</div>
		</div> -->
		<div class="spot-wrap" :id="'section'+index" v-for="(item,index) in lightspotList" :key="index"
			:style="{backgroundImage:'url('+ item.image +')'}">
			<div class="spot-intr" :class="(index+1) % 2 == 0 ? 'display-right' : 'display-left'">
				<div class="title">{{item.name}}</div>
				<img class="line-img" src="../../../assets/images/smart0/line.png" alt="">
				<div class="" v-for="(desItem,desIndex) in item.desList" :key="desIndex">
					<div class="subname">{{desItem.subname}}</div>
					<div class="desc">{{desItem.des}}</div>
				</div>
				<el-button class="spot-btn" @click="followTap">关注订制</el-button>
			</div>
		</div>
		<div class="future-house">
			<img class="future-img" src="../../../assets/images/smart0/future-house.png" alt="">
			<div class="future-intr">
				<div class="title">未来城市住宅</div>
				<img class="line-img" src="../../../assets/images/smart0/line.png" alt="">
				<div class="desc">
					从我国每平方公里居住一万人的新城规划来看，居住用地为25~40万平米，零碳智慧住宅小区是2-3层住宅，按照1.0~1.2的容积率建设，即可满足一万人居住，因此适合在我国所有城市居住区建设，让人人共享绿色环保、健康智能的建设成果。
				</div>
				<el-button class="spot-btn" @click="followTap">关注订制</el-button>
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import {myBrowser} from '@/common/app.js';
	export default {
		components: {
			Header2022,
			Footer2022,
		},
		data() {
			return {
				lightspotList: [{
						image: require('../../../assets/images/smart0/green-house.png'),
						name: "Smart0 自主绿能",
						desList: [{
								subname: '光伏建筑一体化设计',
								des: '来自太阳能光伏发电，具有建筑构件和建筑材料的功能，与建筑物形成完美的统一体。'
							},
							{
								subname: '每户≥50㎡光伏屋面',
								des: '实现绿色、环保、清洁电力，自给自足、用电自由，还可向外配送电力。'
							},
						]
					},
					{
						image: require('../../../assets/images/smart0/constant-house.png'),
						name: "Smart0 智慧恒温",
						desList: [{
							subname: '智慧恒温系统',
							des: '全年室温20℃-26℃，人体舒适范围内的最佳温度。'
						}, ]
					},
					{
						image: require('../../../assets/images/smart0/ecology-house.png'),
						name: "Smart0 生态庭院",
						desList: [{
							subname: '自然共生系统',
							des: '绿色植物、光伏与后院停车位相互融合，形成独特的双庭院，一个养花，一个停车。'
						}, ]
					},
					{
						image: require('../../../assets/images/smart0/green-travel.png'),
						name: "Smart0 绿色出行",
						desList: [{
							subname: '出行管理系统',
							des: '利用光伏发电为新能源汽车充电，实现真正的绿色出行。'
						}, ]
					}
				],
				browser:''
			}
		},
		created() {
			this.browser=myBrowser();
		},
		methods: {
			followTap() {
				this.$router.push({
					path: 'smart0-intention'
				});
			},
			// scrollToAnchor(event) {
			// 	const href = event.target.getAttribute('href');
			// 	window.location.hash = href;
			// 	// 可以将滚动位置订制为合适的位置
			// 	// const targetElement = document.querySelector(href);
			// 	// window.scrollTo({ top: targetElement.offsetTop, behavior: 'smooth' });
			// },
		}
	}
</script>

<style lang="scss">
	#Smart0 {
		.el-button {
			font-size: 12px;
			text-align: center;
			cursor: pointer;
			width: 258px;
			color: #FFFFFF;
			background-color: rgba(0, 0, 0, 0);
			height: 42px;
			border: 2px solid #FFFFFF;
			border-radius: 20px;
			box-sizing: border-box;
		}
		.btn-wrap {
			position: absolute;
			bottom: 100px;
			width: 100%;
			display: flex;
			justify-content: center;
		}
		.main-wrap {
			color: #FFFFFF;
			background: url('../../../assets/images/smart0/headbg.png') no-repeat;
			width: 100%;
			height: 100vh;
			background-size: 100% 100%;
			background-position: center center;
			text-align: center;

			.main-title {
				font-size: 42px;
				font-weight: bold;
				line-height: 42px;
				padding-top: 140px;

				img {
					height: 42px;
				}
			}

			.main-subtitle {
				font-size: 18px;
				line-height: 36px;
				padding-top: 30px;
				font-weight: 800;
			}

			.lightspot {
				position: absolute;
				bottom: 175px;
				width: 100%;
				font-size: 20px;

				.el-divider--vertical {
					margin: 0 10px 1px;
				}
			}

		}

		.title {
			font-size: 36px;
			font-weight: 800;
			line-height: 36px;
		}

		.line-img {
			width: 93px;
			margin-top: 35px;
		}

		.desc {
			font-size: 18px;
			line-height: 36px;
		}

		.spot-btn {
			border: 1px solid #000000;
			color: #000000;
		}

		.spot-wrap {
			width: 100%;
			height: 100vh;
			background-size: 100% 100%;
			background-position: center center;
			color: #595959;

			.spot-btn {
				position: absolute;
			}
			.iframe-image{
				width: 100%;
				height: 100vh;
			}
			.spot-intr {
				width: 33%;
				padding-top: 104px;
				box-sizing: border-box;
				height: 100vh;
				position: relative;
				text-align: center;

				&.display-right {
					float: right;
					margin-right: 4%;

					.spot-btn {
						right: 0;
						bottom: 54px;
					}
				}

				&.display-left {
					float: left;
					margin-left: 4%;

					.spot-btn {
						left: 0;
						bottom: 54px;
					}
				}

			}

			.subname {
				font-size: 22px;
				font-weight: 800;
				line-height: 22px;
				margin: 25px 0;
			}
		}

		.future-house {
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.future-intr {
				width: 100%;
				color: #595959;
				padding: 0px 80px;
				box-sizing: border-box;
				margin-bottom: 10px;
				.line-img {
					margin-top: 30px;
				}
				.desc {
					width: 60%;
					margin: 20px 0 25px;
				}

				@media only screen and (max-width:1440px) {
					.desc {
						width: 80%;
						font-size: 16px;
						line-height: 20px;
					}
				}
			}

			.future-img {
				width: 100%;
				object-fit: contain;
				background-color: aliceblue;

			}

		}
	}
</style>